@import "variables";

@font-face {
  font-family: text-security-disc;
  src: url(../assets/fonts/text-security-disc.woff);
}


@mixin reset-style {
  background: none;
  outline: none;
  border: none;
}

@mixin round-shade {
  border-radius: 20px;
  box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.08);
}

@mixin round-shade-focused {
  box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.16);
}

@mixin select-placeholder {
  .dd-title {
    &.dd-placeholder {
      color: $selected-color;
    }
  }
}

@mixin select-arrow {
  .dd-arrow {
    width: 10px;
    height: 5px;
    display: inline-block;
    background: url("/img/dropdown.svg") no-repeat center;

    &.dd-arrow-up {
      transform: rotate(180deg);
    }
  }
}

button {
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  justify-content: space-between;

  .card-form {
    height: 100%;
    overflow: auto;
    padding: 0 30px;

    .card-title {
      font-size: 18px;
      color: #333;
      font-weight: bold;
      margin-bottom: 29px;
      display: flex;
      justify-content: space-between;

      .sub-card-title {
        font-size: 15px;
      }
    }

    .card-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 33px;


      &.card-select-wrap {
        .card-select {
          &:last-child {
            margin-left: 29px;
          }
        }
      }

      .card-label {
        min-width: 120px;
        color: $selected-color;
        font-size: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .card-input {
        width: 100%;
        height: 40px;
        position: relative;

        .input-validation-error-text {
          margin: 5px;
          font-size: 10px;
          color: red;
        }

        &.input-validation-error {
          input {
            box-shadow: 0 2px 0 0 rgba(255, 0, 0, 0.25);
          }
        }

        input {
          @include round-shade;
          box-sizing: border-box;
          width: 100%;
          height: 40px;
          font-size: 12px;
          padding: 10px 20px;
          background-color: #ffffff;
          border: none;
          outline: none;

          &:focus {
            @include round-shade-focused;
          }

          &:read-only {
            background-color: $hover-color;
            box-shadow: none;
            color: #999;
          }

          &.form-control {
            padding-left: 55px;
          }
        }
      }

      .card-select {
        width: 100%;
        height: 40px;

        &.input-validation-error {
          .country-select-container {
            box-shadow: 0 2px 0 0 rgba(255, 0, 0, 0.25);
            border-radius: 20px;
          }
        }

        .input-validation-error-text {
          margin: 5px;
          font-size: 10px;
          color: red;
        }

        input {
          &:focus {
            @include round-shade-focused;
          }

          &:read-only {
            background-color: $hover-color;
            box-shadow: none;
          }

          &.form-control {
            padding-left: 55px;
          }
        }

        .card-checkbox {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          outline: none;
          @include round-shade;

          &:focus {
            @include round-shade-focused;
          }

          .card-checkbox-icon {
            margin: 0 10px;
            width: 20px;
            height: 20px;
            border: 2px solid #4cd964;
            border-radius: 12px;
            box-sizing: border-box;

            &.card-checkbox-checked {
              background: url("/img/outline-check-circle.svg") no-repeat center;
            }
          }

          .card-checkbox-text {
            font-size: 12px;
          }
        }
      }
    }
  }

  .card-buttons {
    display: flex;
    justify-content: space-between;
    margin: 10px 30px 0 30px;

    .logo-block {
      display: flex;
      gap: 5px;

      .pp-logo {
        filter: grayscale(1);
        height: 32px;
      }
    }

    .buttons-block {
      display: flex;
      justify-content: flex-end;
    }

    &.mobile-buttons {
      margin-top: 30px;
    }

    .button-disabled {
      background: #e6e6e6 !important;
    }

    button {
      padding: 9px 16px 8px 16px;
      border: none;
      border-radius: 20px;
      background: none;
      outline: none;
      margin-left: 20px;
      cursor: pointer;

      &:last-child {
        background-color: #4cd964;
        color: #fff;
      }
    }
  }
}

//.card-wrapper .card-form .card-row.card-select-wrap .card-select:last-child
.content-mobile {
  .card-wrapper {
    .card-form {
      padding-left: 20px;

      .card-row {
        flex-direction: column;
        align-items: flex-start;

        .card-label {
          margin-bottom: 8px;
        }

        .card-select {
          &:last-child {
            margin: 0;
          }
        }

        .extra-space {
          margin-bottom: 33px;
        }
      }
    }

    .card-buttons {
      flex-direction: column-reverse;
      gap: 40px;
      margin: 0 20px;

      .logo-block {
        justify-content: center;
      }
    }
  }

  /*.card-buttons {
      display: none;
  }*/
}

input {
  &.select-input, &.select-input:focus {
    border: none;
    outline: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    padding: 0;
    margin: 0;
  }
}

input {
  &.text-security {
    font-family: text-security-disc, sans-serif !important;
  }
}

.auto-aligned {
  margin-bottom: auto;
}

.important-message {
  background: #ffe8ce;
  border: 1px solid #f5e4d6;
  border-radius: 6px;
  margin: 10px 40px;
  padding: 10px;
  font-size: 12px;

  p {
    margin-bottom: 0;
  }
}

.order-params {
  margin-bottom: 30px;
}

.card-input {
  .react-tel-input {

    .flag-dropdown {
      margin-left: 12px;
      border: none;

      .country-list {
        border: none;
        padding: 4px 0;
        margin-top: 8px;
        border-radius: 4px;
        box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.08);

        .country {
          padding: 11px 9px;
        }
      }

      .selected-flag:hover, .selected-flag:focus {
        background: white;
      }
    }

    .form-control:read-only + .flag-dropdown .selected-flag:hover,
    .form-control:read-only + .flag-dropdown .selected-flag:hover {
      background: $hover-color;
    }
  }
}