.crypto-img {
	display: flex;
	align-items: center;
}

.crypto-account-number {
	.crypto-code {
		margin-bottom: 5px;
	}

	.bold-text {
		margin-right: 5px;
	}
}

.crypto-summary {
	display: flex;
	margin-right: 20px;

	.crypto-qr {
		padding: 20px;
		height: 175px;
	}

	.crypto-fields {
		margin: 20px 0 0 0;
		flex: 1;

		.crypto-field {
			margin-bottom: 20px;

			.crypto-input {
				display: flex;
				align-items: center;

				.crypto-copy {
					width: 14px;
					height: 16px;
					display: inline-block;
					background: url(/img/icons/file-copy-24-px.svg) 0 0 no-repeat;
					cursor: pointer;
					padding-right: 10px;
					position: relative;

					&.copy-amount {
						margin-left: 24px;
					}

					.copy-hint {
						opacity: 0;
						transition: opacity 1s ease-out;
						font-size: 12px;
						padding: 3px 6px;
						color: #fff;
						position: absolute;
						right: -75px;
						bottom: -28px;
						background-color: #4cd964;
						border: 1px solid #4cd964;
						border-radius: 6px;

						&.copy-hint-visible {
							opacity: 1;
						}

						&:before {
							content: '';
							width: 0;
							height: 0;
							position: absolute;
							top: -5px;
							left: 3px;
							border-left: 5px solid transparent;
							border-right: 5px solid transparent;
							border-bottom: 5px solid #4cd964;
						}
					}
				}

				.input-text {
					word-break: break-word;
				}
			}

			.crypto-input input[type=radio] {
				display: none;
			}

			.crypto-input label {
				display: flex;
				cursor: pointer;
				position: relative;
				padding-left: 25px;
				margin-right: 0;
				line-height: 18px;
				height: 18px;
				user-select: none;
			}

			.crypto-input label:before {
				content: "";
				display: inline-block;
				width: 17px;
				height: 18px;
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -9px;
				background: url(/img/radio-button-unchecked.svg) 0 1px no-repeat;
			}

			/* Checked */
			.crypto-input input[type=radio]:checked + label:before {
				background: url(/img/radio-button-checked-24-px.svg) 0 1px no-repeat;
			}

			/* Disabled */
			.crypto-input input[type=radio]:disabled + label:before {
				filter: grayscale(100%);
			}

			.crypto-label {
				font-family: Montserrat, sans-serif;
				font-size: 14px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				color: #8e8e93;
				margin-bottom: 18px;
			}

			.crypto-text {
				font-family: Montserrat, sans-serif;
				font-size: 14px;
				font-weight: 500;
				color: #333;
				padding: 11px 13px;
				border-radius: 20px;
				box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.08);
				background-color: #fff;

				a {
					word-wrap: break-word;
					text-decoration: none;
					color: #333;
				}
			}
		}
	}
}

.crypto-info {
	flex: 1;
	padding: 0 20px;

	&-inner {
		margin-bottom: 20px;
		padding: 11px 13px;
		border-radius: 20px;
		box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.08);

		p {
			font-size: 12px;
			margin: 5px 0;
		}
	}
}

.crypto-currency-head {
	display: flex;
	align-items: center;

	.crypto-img {
		width: 30px;

		.account-icon {
			width: 100%;
		}
	}

	.crypto-currency {
		margin-left: 10px;
	}
}

.status-button {
	align-self: flex-end;
	margin: 10px 30px 0 30px;

	button {
		padding: 9px 16px 8px 16px;
		border: none;
		border-radius: 20px;
		background-color: #4cd964;
		outline: none;
		margin-left: 20px;
		color: #fff;
		cursor: pointer;

		&[disabled] {
			background: #e6e6e6;
		}
	}
}

.content-mobile {
	.crypto-summary {
		flex-direction: column;

		.crypto-qr {
			text-align: center;
		}

		.crypto-fields {
			padding: 0 0 0 20px;
		}
	}

	.status-button {
		margin: 10px 20px 0 20px;
	}
}
